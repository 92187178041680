import React from "react"
import { graphql } from "gatsby"
import HuntHashTagWrapper from "../components/HuntHashTagWrapper/HuntHashTagWrapper";
import { FaInstagram } from "react-icons/fa";
import Layout from "../components/layout/Layout/Layout";

// Todo Add listing data for content types other than Pages
export const query = graphql`
  query($journeyId: String!) {
    datoCmsJourney(id: { eq: $journeyId }) {
      socialSharingMessage
      secretHashtag
      exitLink {
        ... on DatoCmsLocalBusiness {
          title
          slug
        }
        ... on DatoCmsPage {
          title
          slug
        }
        ... on DatoCmsPointOfInterest {
          title
          slug
        }
      }
    }
  }
`
const JourneyHashtag = props => {
  return (
    <Layout
     removeNav={true}
     metaTitle={props.data.datoCmsJourney.secretHashtag}
    >
      <HuntHashTagWrapper
        title={props.data.datoCmsJourney.secretHashtag}
        facebookLink="https://www.facebook.com/destinationselsey"
        instagramIcon={<FaInstagram />}
        instagramLink="https://www.instagram.com/destinationselsey"
        twitterLink="https://twitter.com/VisitSelsey"
        pageText={props.data.datoCmsJourney.socialSharingMessage}
        linkText="Done"
        link={props.data.datoCmsJourney.exitLink ? props.data.datoCmsJourney.exitLink.slug : "/"}
      />
    </Layout>
  )
}

export default JourneyHashtag
