import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import GridContainer from "../layout/GridContainer/GridContainer"
import Facebook from "../../images/facebook-logo.svg"
import Twitter from "../../images/twitter-logo.png"

// Import necessary Bulma CSS Framework components.
import "./HuntHashTagWrapper.library.scss"

// Import component specific styles.
import styles from "./HuntHashTagWrapper.module.scss"

const FacebookLogo = {
  src: Facebook,
  alt: 'Facebook Logo',
};

const TwitterLogo = {
  src: Twitter,
  alt: 'Twitter Logo',
};

const HuntHashTagWrapper = props => (
  <div className={styles.huntHashTagWrapper}>
    <div className={styles.blueGradient}></div>
    <GridContainer>
      <div className={styles.huntHashTagContent}>
        <h1 className={styles.huntHashTagTitle + " h1Heavy"}>{props.title}</h1>
        <p className={styles.pageText + " bodyWalk"}>{props.pageText}</p>
        <div className={styles.socialMediaLinks}>
          <a className={styles.firstSocialMedia} href={props.facebookLink} target="_blank" rel="noreferrer" aria-label="Share on facebook">
            <img src={FacebookLogo.src} alt={FacebookLogo.alt}></img>
          </a>
          <a className={styles.secondSocialMedia} href={props.instagramLink} target="_blank" rel="noreferrer" aria-label="Share on Instagram">
            {props.instagramIcon}
          </a>
          <a className={styles.thirdSocialMedia} href={props.twitterLink} target="_blank" rel="noreferrer" aria-label="Share on Twitter">
            <img src={TwitterLogo.src} alt={TwitterLogo.alt}></img>
          </a>
        </div>
        <div className={styles.ctaLink}><Link to={props.link} aria-label="Navigate to the Home page">{props.linkText}</Link></div>
      </div>
    </GridContainer>
  </div>
)

HuntHashTagWrapper.propTypes = {
  title: PropTypes.string,
  facebookLink: PropTypes.string,
  instagramIcon: PropTypes.object,
  instagramLink: PropTypes.string,
  twitterLink: PropTypes.string,
  pageText: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
}

HuntHashTagWrapper.defaultProps = {
  title: ``,
  facebookLink: ``,
  instagramIcon: {},
  instagramLink: ``,
  twitterLink: ``,
  pageText: ``,
  linkText: ``,
  link: ``,
}

export default HuntHashTagWrapper
